import { VueConstructor } from 'vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { State, StateSlice } from '@/store/models';
import { Asset } from '@/store/models/asset';
import PropertyContentInvested from '@/components/properties/property/contentInvested/PropertyContentInvested.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import { formatNumber } from '@/filters/number';
import { convertUTCToLocalDate, transformDate } from '@/filters/date';
import PropertyFiles from '@/components/properties/property/propertyFiles/PropertyFiles.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Footer from '@/components/common/footer/Footer.vue';
import { min } from 'lodash';
import { LoanFirestore } from '@/store/models/Loan';

@Component({
  components: {
    BannerLegal,
    Navbar,
    PropertyContentInvested,
    Footer,
  },
})

export default class PropertyDetail extends Vue {
  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass loans!: StateSlice<LoanFirestore[]>;
  @StateClass premiumLoans!: StateSlice<LoanFirestore[]>;

  @Action getLoansByAsset!: Function;
  @Action getInvestedPremiumAssetLoans!: Function;

  get assetId(): string {
    return this.$route.params.id;
  }

  get asset(): Asset | undefined {
    return this.assets.find(({ id }): boolean => id === this.assetId);
  }

  get premiumAssets(): Asset[] {
    return this.assets.filter(({ premium }): boolean => premium);
  }

  get mergedAsset(): Asset {
    const premiumAssets = this.assets.filter(({ premium }): boolean => premium);
    const mergedAsset = Object.values(premiumAssets).reduce((accumulator, asset): Asset => ({
      ...asset,
      sizeOfFund: (accumulator.sizeOfFund || 0) + asset.sizeOfFund,
      returnYear: (accumulator.returnYear || 0) + asset.returnYear,
      totalCommitmentAmount: (accumulator.totalCommitmentAmount || 0) + asset.totalCommitmentAmount,
      undrawnAmount: (accumulator.undrawnAmount || 0) + asset.undrawnAmount,
    }), {} as Asset);

    mergedAsset.nextInterestPayment = min(premiumAssets.map(({ nextInterestPayment }): number => nextInterestPayment as number)) || 0;
    mergedAsset.nextInterestAmount = premiumAssets.find(({ nextInterestPayment }): boolean => nextInterestPayment === mergedAsset.nextInterestPayment)?.nextInterestAmount || 0;

    return mergedAsset;
  }

  get mergedAverageInterestRate(): number {
    const premiumAssets = this.assets.filter(({ premium }): boolean => premium);
    return Object.values(premiumAssets).reduce((accumulator, asset, index, all): number => accumulator + (asset.averageInterestRate || 0) / all.length, 0);
  }

  @Watch('asset', { immediate: true })
  onAssetChange(newAsset: Asset | undefined): void {
    if (newAsset) {
      this.getLoansByAsset({ id: newAsset.id });
    }
  }

  @Watch('user', { immediate: true })
  onUserChange(): void {
    if (this.user?.id) {
      this.getInvestedPremiumAssetLoans({ investorId: this.user?.id });
    }
  }

  get cards(): { label: string, content: string, component?: VueConstructor<any> }[] | null {
    if (this.asset) {
      return [
        {
          label: 'Size of fund',
          content: this.$tc('common.eur') + formatNumber(this.asset.sizeOfFund, 2, true, '-'),
        },
        {
          label: 'Annualized fund return since start',
          content: `${formatNumber(this.asset.returnYear, 2, true, '-')}%`,
        },
        {
          label: 'Interest payment date',
          content: transformDate(convertUTCToLocalDate(this.asset.nextInterestPayment)) ?? '-',
        },
        {
          label: 'Next interest amount per Note',
          content: this.asset.nextInterestAmount === 'TBD' ? 'TBD' : this.$tc('common.eur') + formatNumber(this.asset.nextInterestAmount, 2, true, '-'),
        },
        {
          label: 'Total commitment amount',
          content: `${this.$tc('common.eur')} ${this.$refs.loanPortfolioList ? formatNumber((this.$refs.loanPortfolioList as any).calculatedTotalCommitedAmount, 2, true, '-') : '-'}`,
        },
        {
          label: 'Undrawn amount',
          content: `${this.$tc('common.eur')} ${this.$refs.loanPortfolioList ? formatNumber((this.$refs.loanPortfolioList as any).calculatedTotalUndrawnAmount, 2, true, '-') : '-'}`,
        },
        {
          label: 'Number of loans',
          content: formatNumber(this.loans.payload?.length || 0, 2, true, '-'),
        },
        {
          label: 'Duration loans',
          content: '',
          component: PropertyFiles,
        },
      ];
    }

    if (this.assetId === 'shared-interest-fund') {
      return [
        {
          label: 'Number of loans',
          content: formatNumber(this.premiumLoans.payload?.length || 0, 2, true, '-'),
        },
      ];
    }

    return null;
  }
}
