import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter, Action } from 'vuex-class';
import PropertyContentInvested from '@/components/properties/property/contentInvested/PropertyContentInvested.vue';
import PropertyInfoInvested from '@/components/properties/property/infoInvested/PropertyInfoInvested.vue';
import PropertyFiles from '@/components/properties/property/propertyFiles/PropertyFiles.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import Footer from '@/components/common/footer/Footer.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { State, StateSlice } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { LoanFirestore } from '@/store/models/Loan';
import { premiumAssetTemplate } from '@/helpers/premium-assets/template';
import { linkAlternate, metaDescriptions, metaLocale, metaTitles, metaURL } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';
import { MetaInfo } from 'vue-meta';
import { PropertyGallery, PropertyInfo, PropertyContent, PropertyMap, PropertyFloorplan } from './index';

@Component({
  components: {
    BannerLegal,
    Navbar,
    WithDownloads,
    PropertyGallery,
    PropertyInfo,
    PropertyInfoInvested,
    PropertyContent,
    PropertyContentInvested,
    PropertyMap,
    PropertyFloorplan,
    Footer,
    PropertyFiles,
  },
})

export default class Property extends Vue {
  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    return {
      title: titleTemplate(this.$t('meta.properties.title').toString()),
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaTitles(this.$t('meta.properties.title').toString()),
        ...metaDescriptions(this.$t('meta.properties.description').toString()),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
      ],
    };
  }

  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass loans!: StateSlice<LoanFirestore[]>;

  @Action getLoansByAsset!: Function;
  @Getter getDownloadsByPath!: Function;
  @Getter isUserLoggedIn!: boolean;
  @Getter isInvestedInAsset!: boolean;
  @Getter getPremiumTotalInvested!: number;
  @Getter getInvestedAssets!: Asset[];

  @Action getInvestedPremiumAssetLoans!: Function;
  @Action openModal!: Function;

  selected: 'general' | 'investor' = 'general';
  options = [
    { text: 'General view', value: 'general' },
    { text: 'Investor view', value: 'investor' },
  ];

  @Watch('user', { immediate: true })
  onUserChange(): void {
    if (this.user?.id) {
      this.getInvestedPremiumAssetLoans({ investorId: this.user?.id });
    }
  }

  get formattedAssets(): Asset[] {
    return this.getInvestedAssets
      .filter(({ premium }): boolean => !premium)
      .map((asset): Asset => {
        asset = {
          id: asset.id,
          ...asset,
        };

        return this.isUserLoggedIn ? asset : { ...asset, ...premiumAssetTemplate(this.$i18n.locale) };
      });
  }

  get placeHolderAsset(): any {
    return {
      name: 'Rax Shared Interest Fund I',
      placeholder: true,
    };
  }

  get investmentView(): boolean {
    return this.isInvestedInAsset && this.selected === 'investor';
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }
}
