import Property from './Property.vue';
import PropertyGallery from './gallery/PropertyGallery.vue';
import PropertyInfo from './info/PropertyInfo.vue';
import PropertyContent from './content/PropertyContent.vue';
import PropertyMap from './map/PropertyMap.vue';
import PropertyFloorplan from './floorplan/PropertyFloorplan.vue';

export {
  Property,
  PropertyGallery,
  PropertyInfo,
  PropertyContent,
  PropertyMap,
  PropertyFloorplan,
};
