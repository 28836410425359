import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { State as StateClass } from 'vuex-class/lib/bindings';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Footer from '@/components/common/footer/Footer.vue';
import Loader from '@/components/common/loader/Loader.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import KeyValueTable from '@/components/common/tables/KeyValueTable/KeyValueTable.vue';
import { PropertyGalleryType } from '@/components/properties/property/gallery/PropertyGallery';
import PropertyGallery from '@/components/properties/property/gallery/PropertyGallery.vue';
import PropertyMap from '@/components/properties/property/map/PropertyMap.vue';
import { storage } from '@/firebase';
import { StateSlice } from '@/store/models';
import { getValueFromTable, Loan as LoanType, LoanFirestore, LoanLabels, LoanTables } from '@/store/models/Loan';

@Component({
  components: {
    BannerLegal,
    Navbar,
    Footer,
    PropertyMap,
    KeyValueTable,
    Loader,
    PropertyGallery,
  },
})

export default class Loan extends Vue {
  @Action getLoansByAsset!: Function;
  @StateClass loans!: StateSlice<LoanFirestore[]>;

  loanLabels = LoanLabels;

  getValueFromTable = getValueFromTable;

  imagesForPropertyGallery: PropertyGalleryType | null = null;

  @Watch('assetID', { immediate: true })
  onAssetChange(assetID: string | undefined): void {
    if (assetID) {
      this.getLoansByAsset({ id: assetID });
    }
  }

  @Watch('loan', { immediate: true })
  onNewLoan(newLoan: LoanType, oldLoan: LoanType): void {
    type Unpacked<T> = T extends (infer U)[] ? U : T;
    let promiseArray: Promise<string>[] | undefined;
    if (newLoan?.images) {
      promiseArray = newLoan.images.map((image): Promise<string> => storage.ref(image).getDownloadURL());
      Promise.all(promiseArray).then((value): void => {
        this.imagesForPropertyGallery = value.map((image): Unpacked<PropertyGalleryType> => ['', image]);
      });
    }
  }

  get assetID(): string | undefined {
    return this.$route.params.assetId;
  }

  get loanID(): string | undefined {
    return this.$route.params.loanId;
  }

  get loan(): LoanFirestore | undefined {
    return this.loans.payload?.find((loan): boolean => loan.id === this.loanID);
  }

  get loanImages(): string[] | undefined {
    return this.loan?.images;
  }

  get loanIsProcessing(): boolean {
    return this.loans.status === 'processing';
  }

  get properties(): LoanFirestore['properties'] | undefined {
    return this.loan?.properties;
  }

  get tables(): LoanFirestore['tables'] | undefined {
    if (this.loan) {
      // eslint-disable-next-line typescript/explicit-function-return-type
      return [...this.loan.tables].sort((a, b) => {
        if (a.title === LoanTables.Conditions && b.title === LoanTables.Metrics) {
          return -1;
        }
        if (a.title === LoanTables.Metrics && b.title === LoanTables.Conditions) {
          return 0;
        }
        return 1;
      });
    }
    return undefined;
  }

  get loanRoll(): LoanFirestore['rentRoll'] | undefined {
    return this.loan?.rentRoll;
  }
}
