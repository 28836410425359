import { Vue, Component, Prop } from 'vue-property-decorator';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    WithDownloads,
    FundDocs,
  },
})
export default class PropertyFiles extends Vue {
  @Prop() asset!: Asset;

  getFilename(path: string): string {
    const splittedPath = path.split('/');
    return splittedPath[splittedPath.length - 1];
  }

  sortFundReports(files: any): any {
    return [...files].sort((a: [string], b: [string]): number => b[0].localeCompare(a[0]));
  }
}
