/** All tables which are mandatory for a loan */
import { firebase } from '@/firebase';

export enum LoanTables {
  Metrics = 'Key Metrics',
  Conditions = 'Key Conditions',
}

export type Table = string[][];
export type TableTitles = string[];
export type TableForFirestore = {
  titles: TableTitles,
  fields: { content: string[] }[],
} | null;

export type LoanTable = {
  title: string | LoanTables.Conditions | LoanTables.Metrics;
  table: TableForFirestore;
}

export type LoanProperty = {
  table: TableForFirestore,
  location: {
    street?: string,
    houseNumber?: string,
    postalCode?: string,
    city: string,
    country: string,
  };
};

/** Watch out what we get from firestore should have an id, just figure that problem out */
export type Loan = {
  /**
   * Rax internal id
   * Starts at 200
   * */
  loanNr: string,
  /** The city to be shown for the entire loan */
  city: string,
  maturityDate: string,
  category: string, // hotel, office etc.
  properties: LoanProperty[], // there will always be at least one property
  /** At least contains two tables with titles in the enum LoanTables, which in turn contain all the mandatory information
   * For now no column titles and only two columns */
  tables: LoanTable[],
  rentRoll: TableForFirestore,
  /** A longer string having most likely some addresses */
  location: string,
  /** The url to an image for the loan */
  images?: string[],
  /**
   * Loans can not be published yet, i.e. they are hidden in bloqify
   * */
  published: boolean;
}

/** These Keys are generated in the Loan and should not be used for generating other types */
type excludedKeys = keyof Pick<Loan, 'properties' | 'tables' | 'rentRoll' | 'images' | 'published' | 'location'>;

export interface LoanFirestore extends Loan {
  id: string,
  createdDateTime: firebase.firestore.Timestamp,
  updatedDateTime: firebase.firestore.Timestamp,
  deleted: boolean
}

/** These labels, as we use them in the loan portfolio table are also the information required in the loan portfolio,
 * they also have to be what has to be entered at least in the metrics and conditions table
 * */
export enum LoanLabels {
  loanNr = '#',
  type = 'Type of Loan', // bridge | investment
  loanAmount = 'Total commitment amount',
  undrawnAmount = 'Undrawn amount',
  yearlyInterest = 'Interest per Annum',
  term = 'Duration (Months)', // called Terms (Months) in the portfolio
  issueDate = 'Issue Date',
  maturityDate = 'Maturity Date',
  city = 'City',
  extValuation = 'Market Value',
  ltv = 'LTV',
  category = 'Category',
}

/** These are directly stored in the loan */
export const manualInputLabels: Required<{
  [key in Exclude<keyof Loan, // it has to contain all the information we need, manual inputs have to be directly in the Loan
    excludedKeys // except it is a ancillary field
    >]:
  LoanLabels
}> = {
  loanNr: LoanLabels.loanNr,
  city: LoanLabels.city,
  maturityDate: LoanLabels.maturityDate,
  category: LoanLabels.category,
};

/** The labels for all the mandatory members in the metrics table, also defines which ones are mandatory */
export const metricsTableLabels: Partial<{ // for the metrics table we cannot check for exhaustiveness yet
  [key in keyof typeof LoanLabels]:
  LoanLabels
}> = {
  ltv: LoanLabels.ltv,
};

/** The labels for all the mandatory members in the conditions table, also defines which ones are mandatory
 * todo the type of the value is not correct */
export const conditionsTableLabels: Required<{
  [key in Exclude<keyof typeof LoanLabels, // it has to contain all the information we need
    keyof typeof metricsTableLabels // except it was already defined in the metrics table
    | keyof typeof manualInputLabels>]: // or in the manual input
  LoanLabels
}> = {
  loanAmount: LoanLabels.loanAmount,
  issueDate: LoanLabels.issueDate,
  type: LoanLabels.type,
  term: LoanLabels.term,
  yearlyInterest: LoanLabels.yearlyInterest,
  extValuation: LoanLabels.extValuation,
};

/** This information is always present on the loan */
export type LoanFixedInformation = keyof typeof LoanLabels | Exclude<keyof Loan, excludedKeys>;

/** The type of the loan as we use it for the loan portfolio
 * this makes sure we only use information stored in one of the two tables we now are there for sure, or the loan in general
 * */
export type LoanPortfolio = Required<{
  [key in LoanFixedInformation]: {
    table: LoanTables | 'noTable',
    label: string // so this means we could have different labels in this table then what we use as labels in the tables in firestore
  }
}>;

/** Helper for the loan portfolio, this should serve as the basis to find the information in the different tables */
export const loanPortfolio: LoanPortfolio = {
  loanNr: {
    table: 'noTable', // search here
    label: LoanLabels.loanNr, // for this label
  },
  city: {
    table: 'noTable',
    label: LoanLabels.city,
  },
  category: {
    table: 'noTable',
    label: LoanLabels.category,
  },
  maturityDate: {
    table: 'noTable',
    label: LoanLabels.maturityDate,
  },
  ltv: {
    table: LoanTables.Metrics,
    label: LoanLabels.ltv,
  },
  type: {
    table: LoanTables.Conditions,
    label: LoanLabels.type,
  },
  loanAmount: {
    table: LoanTables.Conditions,
    label: LoanLabels.loanAmount,
  },
  undrawnAmount: {
    table: LoanTables.Conditions,
    label: LoanLabels.undrawnAmount,
  },
  yearlyInterest: {
    table: LoanTables.Conditions,
    label: LoanLabels.yearlyInterest,
  },
  term: {
    table: LoanTables.Conditions,
    label: LoanLabels.term,
  },
  issueDate: {
    table: LoanTables.Conditions,
    label: LoanLabels.issueDate,
  },
  extValuation: {
    table: LoanTables.Conditions,
    label: LoanLabels.extValuation,
  },
};

export const getValueFromTable = (loan: LoanFirestore, key: keyof typeof LoanLabels): string | null => {
  if (!loan.tables) {
    return null;
  }

  // Look up table information
  const loanPortfolioElement = loanPortfolio[key];

  if (!loanPortfolioElement) {
    return null;
  }

  if (loanPortfolioElement.table === 'noTable') {
    return loan[key];
  }

  // Find the right table where the data for this key lives
  const table = loan.tables.find(({ title: type }: LoanTable): boolean => type === loanPortfolioElement.table);

  if (!table) {
    return null;
  }

  const row = table.table?.fields.find(({ content }): boolean => content[0] === loanPortfolioElement.label);

  return row?.content?.length ? row.content[1] : null;
};
