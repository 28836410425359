import { render, staticRenderFns } from "./PropertyInfoInvested.html?vue&type=template&id=19604300&scoped=true&"
import script from "./PropertyInfoInvested.ts?vue&type=script&lang=ts&"
export * from "./PropertyInfoInvested.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/properties/_info.scss?vue&type=style&index=0&id=19604300&scoped=true&lang=scss&"
import style1 from "./PropertyInfoInvested.scss?vue&type=style&index=1&id=19604300&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19604300",
  null
  
)

export default component.exports