import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class/lib/bindings';
import moment from 'moment';
import { StateSlice, State } from '@/store/models';
import { LoanLabels, LoanFirestore, getValueFromTable } from '@/store/models/Loan';
import { Asset } from '@/store/models/asset';

@Component({})
export default class PropertyContentInvested extends Vue {
  @Prop() asset!: Asset;

  @StateClass loans!: StateSlice<LoanFirestore[]>;
  @StateClass premiumLoans!: StateSlice<LoanFirestore[]>;
  @StateClass investments!: StateSlice<LoanFirestore[]>;
  @StateClass user!: State['user'];

  getValueFromTable = getValueFromTable;

  sortingColumn: string = 'loanNr';
  sortingAsc: boolean = true;
  calculatedTotalUndrawnAmount: number = 0;
  calculatedTotalCommitedAmount: number = 0;

  loansExtended: { [loanId: string]: boolean } = {}

  get loanLabels(): Partial<{ [key in keyof typeof LoanLabels]: string }> {
    return {
      loanNr: '#',
      type: 'Type of Loan',
      loanAmount: 'Commitment Amount',
      undrawnAmount: 'Undrawn Amount',
      yearlyInterest: 'Interest/Y',
      ltv: 'LTV',
      maturityDate: 'Maturity Date',
      city: 'City',
      category: 'Category',
    };
  }

  get getTotalShares(): number {
    return this.investments[0]?.boughtSharesTotal || 0;
  }

  get noteTypeA(): boolean {
    return this.user?.type === 'A';
  }

  get assetId(): string {
    return this.$route.params.id;
  }

  get currentLoans(): (LoanFirestore & { debts: Asset['debts'], assetId?: string })[] {
    return this.assetId === 'shared-interest-fund' ? this.premiumLoans.payload : this.loans.payload;
  }

  // format loans for table
  get formattedLoans(): any {
    let calculatedTotalUndrawnAmount = 0;
    let calculatedTotalCommitedAmount = 0;
    const sortedLoans = this.currentLoans?.map((loan): any => ({
      debts: loan.debts,
      assetId: loan.assetId,
      // get values from objects for metrics and conditions table
      ...Object.keys(this.loanLabels)
          .reduce(
            (accumulator, key: any): any => {
              const value = getValueFromTable(loan, key);
              if (key === 'undrawnAmount' && value) {
                calculatedTotalUndrawnAmount += Number(value?.substring(value.indexOf(' ') >= 0 ? value.indexOf(' ') : 1).replace(/\./g, ''));
              } else if (key === 'loanAmount' && value) {
                calculatedTotalCommitedAmount += Number(value?.substring(value.indexOf(' ') >= 0 ? value.indexOf(' ') : 1).replace(/\./g, ''));
              }
              return { ...accumulator, [key]: value };
            },
            { id: loan.id },
        ),
      }))
      // specific sorting based on maturityDate and loanAmount
      .sort((a, b): number => {
        if (this.sortingColumn === 'maturityDate') {
          if (b.maturityDate === undefined) {
            return -1;
          }

          const aDate = a.maturityDate?.seconds ? moment(a.maturityDate.toDate()) : moment(a.maturityDate, ['DD/MM/YYYY', 'DD-MM-YYYY']);
          const bDate = b.maturityDate?.seconds ? moment(b.maturityDate.toDate()) : moment(b.maturityDate, ['DD/MM/YYYY', 'DD-MM-YYYY']);

          return moment(aDate).valueOf() - moment(bDate).valueOf();
        }

        if (['loanAmount', 'undrawnAmount', 'yearlyInterest'].includes(this.sortingColumn)) {
          const aAmount = parseFloat(
            (a[this.sortingColumn] || '').replace(/[^0-9.,]+/, '')
              .replaceAll('.', '')
              .replaceAll(',', '.')
              .replaceAll('%', ''),
          ) || 0;

          const bAmount = parseFloat(
            (b[this.sortingColumn] || '').replace(/[^0-9.,]+/, '')
              .replaceAll('.', '')
              .replaceAll(',', '.')
              .replaceAll('%', ''),
          ) || 0;

          return aAmount - bAmount;
        }

        return String(a[this.sortingColumn]).localeCompare(String(b[this.sortingColumn]));
      });

    this.calculatedTotalUndrawnAmount = calculatedTotalUndrawnAmount;
    this.calculatedTotalCommitedAmount = calculatedTotalCommitedAmount;
    return this.sortingAsc ? sortedLoans : sortedLoans.reverse();
  }

  sortByColumn(key: string): void {
    if (this.sortingColumn !== key) {
      this.sortingColumn = key;
      this.sortingAsc = true;
    } else {
      this.sortingAsc = !this.sortingAsc;
    }
  }

  onLoanClick(loan: any): void {
    this.loansExtended = {
      ...this.loansExtended,
      [loan.id]: !this.loansExtended[loan.id],
    };
  }

  onLoanDetailsClick(loan: any): void {
    this.$router.push({ name: 'loan', params: { assetId: this.asset?.id || loan?.assetId || '', loanId: loan.id, lang: this.$route.params.lang } });
  }
}
