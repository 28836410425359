import { render, staticRenderFns } from "./Loan.html?vue&type=template&id=25c8aaf2&scoped=true&"
import script from "./Loan.ts?vue&type=script&lang=ts&"
export * from "./Loan.ts?vue&type=script&lang=ts&"
import style0 from "./Loan.scss?vue&type=style&index=0&id=25c8aaf2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c8aaf2",
  null
  
)

export default component.exports