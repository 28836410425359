import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import to from 'await-to-js';
import { Asset, AssetAddress } from '@/store/models/asset';

type Location = { lat: number, lng: number };

@Component({})
export default class PropertyMap extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Ref() map: any;

  locations: Location[] = [];
  mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
  };

  mounted(): void {
    if (this.asset) {
      this.initializeMap(this.asset);
    }
  }

  @Watch('asset')
  async onAssetChange(newAsset: Asset): Promise<void> {
    if (this.asset && this.asset.city) {
      this.initializeMap(newAsset);
    }
  }

  getAddressString(asset: Asset | AssetAddress): string {
    return asset ? `${asset.street || ''} ${asset.houseNumber || ''} ${asset.city || ''} ${asset.country || ''}`.trimLeft() : '';
  }

  async initializeMap(asset: Asset): Promise<any> {
    // @ts-ignore
    const [mapsApiError, mapsApi] = await to(this.$gmapApiPromiseLazy());
    if (mapsApiError) {
      return;
    }

    // @ts-ignore
    const geocoder = new mapsApi.maps.Geocoder();
    // @ts-ignore
    const bounds = new mapsApi.maps.LatLngBounds();

    const addressStrings: string[] = asset.addresses ?
      [...asset.addresses.map(this.getAddressString)] :
      [this.getAddressString(asset)];

    const locationsPromises = addressStrings.map((address): Promise<Location> =>
      new Promise((resolve, reject): void =>
        geocoder.geocode({ address }, (results, status): void => {
          if (status === 'OK') {
            const location = results[0].geometry.location as Location;
            bounds.extend(location);
            resolve(location);
          } else {
            console.log('Error', status); // eslint-disable-line no-console
            reject();
          }
        })));

    this.locations = await Promise.all(locationsPromises);

    if (this.locations.length > 1) {
      this.$nextTick((): void => {
        if (this.map) {
          this.map.$mapPromise.then((map): void => {
            map.fitBounds(bounds);
          });
        }
      });
    }
  }
}
